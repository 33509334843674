import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { PageHeader, Drawer, Skeleton, Tabs, List } from "antd";
import { Page } from "@components/layout";
import {
    useGetSurveyMediaKeyNamesQuery,
    useGetSurveyMediasQuery,
    useGetSurveyGalleryQuery,
    useDetachSurveyMediasMutation,
    useAttachSurveyMediasMutation
} from "@store/api/surveys";
import FileUpload from "@components/FileUpload";
import MediaListItem from "@components/MediaListItem";
import GalleryListItem from "@components/GalleryListItem/GalleryListItem";
import ErrorMessage from "@components/ErrorMessage";
import { BackgroundSignature } from "@components/MediaListItem/MediaListItem";

const FilesAndImages: React.FC = () => {
    const navigate = useNavigate();
    const { id: surveyId } = useParams();
    const handleBack = () => navigate(`/survey/${surveyId}`);

    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
    const [keyNameToUpload, setKeyNameToUpload] = useState<string | null>(null);
    const [imageBackground, setImageBackground] = useState<BackgroundSignature>({});
    const [tabsActiveKey, setTabsActiveKey] = useState<string | null>(null);

    useEffect(() => {
        if (keyNameToUpload !== null) setIsDrawerOpen(true);
    }, [keyNameToUpload]);

    useEffect(() => {
        let timeout: ReturnType<typeof setTimeout> | null = null;
        if (isDrawerOpen === false) {
            timeout = setTimeout(() => {
                setKeyNameToUpload(null);
            }, 500);
        }
        return () => {
            if (timeout) clearTimeout(timeout);
        };
    }, [isDrawerOpen]);

    const {
        data: keyNames,
        isFetching: loadingKeyNames,
        error: errorMediaKeyNames
    } = useGetSurveyMediaKeyNamesQuery(surveyId!);
    const {
        data: medias,
        isFetching: loadingMedias,
        error: errorMedias
    } = useGetSurveyMediasQuery(surveyId!);
    const {
        data: gallery,
        isFetching: loadingGallery,
        error: errorGallery
    } = useGetSurveyGalleryQuery(surveyId!);
    const [detatchMedia, { isLoading: isLoadingDetachMedia, error: detatchMediaError }] =
        useDetachSurveyMediasMutation();
    const [attachMedia, { isLoading: isLoadingAttachMedia, error: attachMediaError }] =
        useAttachSurveyMediasMutation();

    const isLoading = loadingKeyNames || loadingGallery || loadingMedias;
    const error =
        errorMediaKeyNames || errorMedias || errorGallery || detatchMediaError || attachMediaError;

    const getDrawerTitle = () => {
        return (
            "Attach Media to: " +
                keyNames?.find((keyName) => keyName.keyname === keyNameToUpload)?.description || ""
        );
    };

    return (
        <div>
            <PageHeader onBack={handleBack} title={"Files and Images"} />
            <Page>
                {!error ? (
                    <List
                        className="demo-loadmore-list"
                        loading={isLoading || isLoadingAttachMedia}
                        itemLayout="horizontal"
                        dataSource={keyNames}
                        renderItem={(item) => {
                            const association = medias?.find(
                                (media) => media.pivot.fieldname === item.keyname
                            );
                            return (
                                <MediaListItem
                                    item={item}
                                    association={association}
                                    imageBackground={imageBackground}
                                    setImageBackground={setImageBackground}
                                    setKeyNameToUpload={setKeyNameToUpload}
                                    detatchMedia={detatchMedia}
                                />
                            );
                        }}
                    />
                ) : (
                    <ErrorMessage message="There was an error, please try again later" />
                )}
            </Page>
            {!error && (
                <Drawer
                    title={getDrawerTitle()}
                    placement="right"
                    onClose={() => setIsDrawerOpen(false)}
                    visible={isDrawerOpen}
                    size="large"
                >
                    {keyNameToUpload && gallery !== undefined ? (
                        <Tabs
                            centered={true}
                            activeKey={tabsActiveKey || "upload"}
                            onChange={(key) => setTabsActiveKey(key)}
                        >
                            <Tabs.TabPane tab="Upload New" key="upload">
                                <FileUpload onSuccessUpload={() => setTabsActiveKey("gallery")} />
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Use Existing From Gallery" key="gallery">
                                <List
                                    itemLayout="vertical"
                                    size="large"
                                    loading={isLoadingDetachMedia}
                                    pagination={{ pageSize: 3, position: "top" }}
                                    dataSource={gallery}
                                    renderItem={(item) => {
                                        return (
                                            <GalleryListItem
                                                item={item}
                                                keyNameToUpload={keyNameToUpload}
                                                setIsDrawerOpen={setIsDrawerOpen}
                                                attachMedia={attachMedia}
                                            />
                                        );
                                    }}
                                />
                            </Tabs.TabPane>
                        </Tabs>
                    ) : (
                        <Skeleton />
                    )}
                </Drawer>
            )}
        </div>
    );
};

export default FilesAndImages;
