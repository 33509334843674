import { Button, PageHeader, Spin } from "antd";
import React, { useEffect } from "react";
import {
    useGetSurveyQuestionQuery,
    useGetSurveySlotsQuery,
    useUpdateSurveyQuestionMutation
} from "@store/api/surveys";

import { Error422 } from "@store/index";
import ErrorMessage from "@components/ErrorMessage";
import { Link } from "react-router-dom";
import MultilingualForm from "@components/forms/multilingual";
import { SurveyQuestionFormItems } from "../types";
import { mapErrorsToFields } from "@utils/map-errors-to-fields";
import { performMutation } from "@utils/perform-mutation";
import { questionInputs } from "../form/new-question-inputs";
import styles from "../NewSurvey.module.scss";
import { useParams } from "react-router";

const UpdateQuestion: React.FC = () => {
    const { id: surveyId, questionId } = useParams();
    const [form] = MultilingualForm.useForm();

    const [updateSurvey, { isLoading: isLoadingUpdateSurvey }] = useUpdateSurveyQuestionMutation();

    const {
        data: question,
        isLoading: isLoadingQuestions,
        isFetching: isFetchingQuestions,
        error: errorQuestion,
        refetch: refetchQuestion
    } = useGetSurveyQuestionQuery(questionId);

    const {
        data: futureSlots,
        isLoading: isLoadingSlots,
        isFetching: isFetchingSlots,
        error: errorSlots,
        isUninitialized: isUninitializedSlots
    } = useGetSurveySlotsQuery(
        { surveyId: surveyId!, unpublishedOnly: true },
        { skip: !!!surveyId }
    );

    useEffect(() => {
        if (question && form) {
            form.resetFields();
            form.setFieldsValue(question);
        }
    }, [question, form]);

    const handleOnSubmit = async (values: SurveyQuestionFormItems) => {
        await performMutation({
            mutation: async () => await updateSurvey({ ...values, questionId }).unwrap(),
            successMessage: "The question was updated successfully",
            errorMessage: "There was an error while updating the question",
            onSuccessCallback: () => refetchQuestion(),
            formFieldsResetter: () => form.resetFields(),
            formFieldsSetter: (error: Error422) => {
                const valuesWithErrors = mapErrorsToFields({ error, values });
                form.setFields(valuesWithErrors);
            }
        });
    };

    const error = errorSlots || errorQuestion;
    const loading =
        isLoadingUpdateSurvey ||
        isLoadingQuestions ||
        isFetchingQuestions ||
        isLoadingSlots ||
        isFetchingSlots ||
        isUninitializedSlots;

    if (error) return <ErrorMessage message="There was an error, please try again later" />;
    return (
        <Spin spinning={loading}>
            <PageHeader
                title="Survey question"
                extra={[
                    <Link key="link" to={`/survey/${surveyId}/questions/${questionId}/options`}>
                        <Button>Options</Button>
                    </Link>
                ]}
            />
            <div className={styles.page}>
                <MultilingualForm
                    form={form}
                    inputs={questionInputs({ futureSlots: futureSlots || [] })}
                    onSubmit={handleOnSubmit}
                    actions={[
                        <Button key="submit" type="primary" htmlType="submit">
                            Update question
                        </Button>
                    ]}
                />
            </div>
        </Spin>
    );
};

export default UpdateQuestion;
