import { MultilingualFormInput } from "@components/forms/multilingual";
import { SelectOption } from "@components/forms/multilingual";
import inputs from "./intputs";

interface Timezone {
    id: string;
}

const mapTimezonesToOptions = (timezones: Timezone[]): SelectOption[] => {
    return timezones.map((timezone: Timezone) => ({ label: timezone.id, value: timezone.id }));
};

const formInputs: ({ timezones }: { timezones: Timezone[] }) => MultilingualFormInput[] = ({
    timezones
}) => [
    ...inputs,
    {
        name: "closed_message",
        label: "Survey closed message",
        type: "rich-text-editor"
    },
    {
        name: "pre_message",
        label: "Pre survey start message",
        type: "rich-text-editor"
    },
    {
        name: "post_message",
        label: "Post survey finished message",
        type: "rich-text-editor"
    },
    {
        name: "domain",
        label: "Domain",
        type: "text",
        multilingual: false
    },
    {
        name: "timezone",
        label: "Timezone",
        type: "select",
        multilingual: false,
        options: mapTimezonesToOptions(timezones),
        grid: { col: { span: 24 } },
        inputProps: {
            showSearch: true
        }
    },
    {
        name: "open_at",
        label: "Open at",
        type: "date-picker",
        multilingual: false,
        inputProps: {
            showTime: { format: "HH:mm" },
            format: "DD-MM-YYYY HH:mm"
        },
        grid: { col: { span: 12 } }
    },
    {
        name: "close_at",
        label: "Closed at",
        type: "date-picker",
        multilingual: false,
        inputProps: {
            showTime: { format: "HH:mm" },
            format: "DD-MM-YYYY HH:mm"
        },
        grid: { col: { span: 12 } }
    },
    {
        name: "is_active",
        label: "Is active",
        type: "switch",
        multilingual: false
    }
];

export default formInputs;
