import { AdditionalSlotFields } from "./Slots";
import { FormInstance } from "antd";
import { Moment } from "moment";
import moment from "moment";
import momentTimezone from "moment-timezone";
import { timeZoneToUtc } from "@utils/timezones";

export const checkDate = ({
    startDate,
    finishDate,
    surveyTimezone,
    form
}: {
    startDate: string | Moment | null | undefined;
    finishDate: string | Moment | null | undefined;
    surveyTimezone: string | undefined;
    form: FormInstance;
}) => {
    let isValidStartDate = false;
    let isValidFinishDate = false;

    const minimumDate = momentTimezone
        .tz(
            moment()?.set({ second: 0 }).toISOString(),
            "YYYY-MM-DD HH:mm:ss",
            surveyTimezone || "utc"
        )
        .format("YYYY-MM-DD HH:mm:ss");

    if (!startDate) isValidStartDate = true;
    else {
        const selectedStartDate = timeZoneToUtc({
            time: startDate,
            zone: surveyTimezone
        });

        isValidStartDate = new Date(selectedStartDate!) >= new Date(minimumDate);
    }

    if (!finishDate) isValidFinishDate = true;
    else {
        const selectedStartDate = timeZoneToUtc({
            time: startDate,
            zone: surveyTimezone
        });

        const selectedFinishDate = timeZoneToUtc({
            time: finishDate,
            zone: surveyTimezone
        });

        const isFinishAfterStart =
            selectedStartDate !== null
                ? new Date(selectedFinishDate!) > new Date(selectedStartDate)
                : true;

        isValidFinishDate =
            new Date(selectedFinishDate!) >= new Date(minimumDate) && isFinishAfterStart;

        if (!isValidFinishDate) {
            form.setFields([
                {
                    name: AdditionalSlotFields.finishAt,
                    value: null,
                    errors: [
                        !isFinishAfterStart
                            ? "The 'finish at' must be after the 'start at'"
                            : "The 'finish at' date must be a date in the future"
                    ]
                }
            ]);
            form.scrollToField(AdditionalSlotFields.finishAt);
        }
    }

    if (!isValidStartDate) {
        form.setFields([
            {
                name: AdditionalSlotFields.startAt,
                value: null,
                errors: [
                    "The 'start at' date must be a date in the future, please use the 'publish immediately' field if you wish to publish the question group now"
                ]
            }
        ]);
        form.scrollToField(AdditionalSlotFields.startAt);
    }

    return isValidFinishDate && isValidStartDate;
};

export const checkFinishDate = ({
    finishDate,
    surveyTimezone,
    form
}: {
    finishDate: string | Moment | null | undefined;
    surveyTimezone: string | undefined;
    form: FormInstance;
}) => {
    let isValidFinishDate = false;

    const minimumDate = momentTimezone
        .tz(
            moment()?.set({ second: 0 }).toISOString(),
            "YYYY-MM-DD HH:mm:ss",
            surveyTimezone || "utc"
        )
        .format("YYYY-MM-DD HH:mm:ss");

    if (!finishDate) isValidFinishDate = true;
    else {
        const selectedFinishDate = timeZoneToUtc({
            time: finishDate,
            zone: surveyTimezone
        });

        isValidFinishDate = new Date(selectedFinishDate!) >= new Date(minimumDate);
    }

    if (!isValidFinishDate) {
        form.setFields([
            {
                name: AdditionalSlotFields.finishAt,
                value: null,
                errors: ["The 'finish at' date must be a date in the future"]
            }
        ]);
        form.scrollToField(AdditionalSlotFields.finishAt);
    }

    return isValidFinishDate;
};
