import { List, PageHeader, Spin } from "antd";
import {
    useChangeCssStylesMutation,
    useGetCssStylesKeyNamesQuery,
    useGetCssStylesQuery
} from "@store/api/surveys";
import { useNavigate, useParams } from "react-router-dom";

import CSSStyleListItem from "@components/CSSStyleListItem";
import ErrorMessage from "@components/ErrorMessage";
import { Page } from "@components/layout";
import React from "react";
import { performMutation } from "../../utils/perform-mutation";

const FilesAndImages: React.FC = () => {
    const navigate = useNavigate();
    const { id: surveyId } = useParams();
    const {
        data: cssStyleKeyNames,
        isFetching: isFetchingCssStylesKeyNames,
        isLoading: isLoadingCssStyleKeyNames,
        error: errorGetCssKeyNames
    } = useGetCssStylesKeyNamesQuery(surveyId!);

    const {
        data: cssStyles,
        isFetching: isFetchingCssStyles,
        isLoading: isLoadingCssStyles,
        error: errorGetCssStyles
    } = useGetCssStylesQuery(surveyId!);

    const [updateStyles, { isLoading: isLoadingUpdateStyles }] = useChangeCssStylesMutation();

    const handleUpdateStyles = async (values: { fieldname: string; fieldvalue: string | null }) => {
        await performMutation({
            mutation: async () =>
                await updateStyles({
                    fieldname: values.fieldname,
                    fieldvalue: values.fieldvalue,
                    surveyId
                }).unwrap(),
            successMessage: "The styles have been updated successfully",
            errorMessage: "There was an error while updating the styles"
        });
    };

    const error = errorGetCssKeyNames || errorGetCssStyles;
    const loading =
        isFetchingCssStylesKeyNames ||
        isFetchingCssStyles ||
        isLoadingCssStyles ||
        isLoadingCssStyleKeyNames ||
        isLoadingUpdateStyles;

    if (error) return <ErrorMessage message="There was an error, please try again later" />;
    return (
        <Spin spinning={loading}>
            <PageHeader onBack={() => navigate(`/survey/${surveyId}`)} title={"Styles"} />
            <Page>
                <List
                    className="demo-loadmore-list"
                    itemLayout="horizontal"
                    dataSource={cssStyleKeyNames}
                    renderItem={(item) => {
                        const match = cssStyles?.find(
                            (cssStyle) => cssStyle.fieldname === item.keyname
                        );
                        return (
                            <CSSStyleListItem
                                item={item}
                                match={match || null}
                                isLoadingStyles={isFetchingCssStyles}
                                onUpdate={handleUpdateStyles}
                            />
                        );
                    }}
                />
            </Page>
        </Spin>
    );
};

export default FilesAndImages;
