import { Button, PageHeader, Space } from "antd";
import { Layout, Left, Right } from "@components/layout";
import { Link, Outlet } from "react-router-dom";
import { useGetSurveyQuestionsQuery, useUpdateQuestionsOrderMutation } from "@store/api/surveys";
import { useNavigate, useParams } from "react-router";

import Card from "@components/Card/Card";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import React from "react";
import { SurveyQuestionType } from "@store/api/types";
import moment from "moment";
import { performMutation } from "../../../utils/perform-mutation";

const Questions: React.FC = () => {
    const navigate = useNavigate();
    const { id: surveyId, questionId } = useParams();

    const [updateOrder, { isLoading: isLoadingUpdateOrder }] = useUpdateQuestionsOrderMutation();

    const {
        data: questions,
        isFetching: isFetchingQuestions,
        isLoading: isLoadingQuestions,
        error: errorQuestions,
        refetch: refetchQuestions
    } = useGetSurveyQuestionsQuery({ surveyId: surveyId! });

    const handleSaveOrder = async (items: any) => {
        const order = items.map(({ id }: any) => id);
        await performMutation({
            mutation: async () => await updateOrder({ surveyId, order }).unwrap(),
            onSuccessCallback: () => refetchQuestions(),
            successMessage: "The order of the questions was updated successfully",
            errorMessage: "There was an error while updating the order of the questions"
        });
    };

    const error = errorQuestions;
    const loading = isFetchingQuestions || isLoadingQuestions || isLoadingUpdateOrder;

    if (error) return <ErrorMessage message="There was an error, please try again later" />;
    return (
        <>
            <PageHeader
                onBack={() => navigate(`/survey/${surveyId}`)}
                title={<Space direction="horizontal">Survey questions</Space>}
                extra={[
                    <Link key="question-groups" to={`/survey/${surveyId}/slots`}>
                        <Button>Question groups</Button>
                    </Link>
                ]}
            />
            <Layout height="calc(100vh - 150px)">
                <Left
                    height="calc(100vh - 150px)"
                    canOrder
                    loadingItems={loading}
                    onSaveOrder={handleSaveOrder}
                    action={
                        <Space direction="horizontal" align="end">
                            <Button
                                data-testid="new-question-button"
                                onClick={() => navigate(`/survey/${surveyId}/questions/new`)}
                                size="small"
                            >
                                + New
                            </Button>
                        </Space>
                    }
                    withSearch={false}
                    title="Questions"
                    items={questions || []}
                    renderItem={(question: SurveyQuestionType) => (
                        <Link
                            to={`/survey/${surveyId}/questions/${question.id}`}
                            data-testid="question-card"
                        >
                            <Card
                                published={
                                    question.created_at
                                        ? moment(question.created_at).fromNow()
                                        : undefined
                                }
                                updated={
                                    question.updated_at
                                        ? moment(question.updated_at).fromNow()
                                        : undefined
                                }
                                title={question.title?.it || question.title?.en || ""}
                                selected={surveyId !== undefined && question.id === questionId}
                            />
                        </Link>
                    )}
                />
                <Right height="calc(100vh - 150px)">
                    <Outlet />
                </Right>
            </Layout>
        </>
    );
};

export default Questions;
