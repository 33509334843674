import React, { CSSProperties, useMemo } from "react";

import styles from "@components/layout/Layout.module.scss";

export interface LayoutProps {
    height?: string | number;
}

const Layout: React.FC<LayoutProps> = ({ children, height }) => {
    const style: CSSProperties = useMemo(() => {
        const _styles: CSSProperties = {};

        if (height !== undefined) {
            _styles.height = height;
        }

        return _styles;
    }, [height]);

    return (
        <div className={styles.layout} style={style}>
            {children}
        </div>
    );
};

export default Layout;
