import { Button, Drawer } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";

import MultilingualForm from "@components/forms/multilingual";
import { SurveyQuestionOptionFormItems } from "./types";
import { optionInputs } from "./form/new-option-inputs";
import { performMutation } from "@utils/perform-mutation";
import { useAddSurveyQuestionOptionMutation } from "@store/api/surveys";

const NewSurveyQuestionOption: React.FC = () => {
    const navigate = useNavigate();
    const { id, questionId } = useParams();
    const [visible, setVisible] = useState<boolean>(false);
    const [addOption, { isLoading }] = useAddSurveyQuestionOptionMutation();

    useEffect(() => {
        setVisible(true);
    }, []);

    const handleCloseDrawer = () => {
        setVisible(false);
        setTimeout(() => {
            navigate(`/survey/${id}/questions/${questionId}/options`);
        }, 400);
    };

    const handleOnSubmit = async (values: SurveyQuestionOptionFormItems) => {
        await performMutation({
            mutation: async () =>
                await addOption({
                    questionId,
                    ...values
                }).unwrap(),
            successMessage: "The question option was created successfully",
            errorMessage: "There was an error while creating the question option",
            onSuccessCallback: () => handleCloseDrawer()
        });
    };

    return (
        <Drawer width={550} visible={visible} onClose={handleCloseDrawer} title="Create new option">
            <MultilingualForm
                inputs={optionInputs}
                onSubmit={handleOnSubmit}
                actions={[
                    <Button key="submit" type="primary" htmlType="submit" loading={isLoading}>
                        Create new
                    </Button>
                ]}
            />
        </Drawer>
    );
};

export default NewSurveyQuestionOption;
