import { Button, PageHeader } from "antd";
import { useAddSurveyMutation, useGetTimezonesQuery } from "@store/api/surveys";

import { Error422 } from "@store/index";
import ErrorMessage from "@components/ErrorMessage";
import { Link } from "react-router-dom";
import MultilingualForm from "@components/forms/multilingual";
import React from "react";
import { SurveyFormItems } from "./types";
import inputs from "./form/new-survey-inputs";
import { mapErrorsToFields } from "@utils/map-errors-to-fields";
import { performMutation } from "@utils/perform-mutation";
import styles from "./NewSurvey.module.scss";
import { timeZoneToUtc } from "@utils/timezones";
import { useNavigate } from "react-router";

const NewSurvey: React.FC = () => {
    const dateFormat = "YYYY-MM-DD HH:mm";
    const navigate = useNavigate();
    const [form] = MultilingualForm.useForm();

    const [addSurvey, { data, isLoading, isSuccess, reset }] = useAddSurveyMutation();

    const {
        data: timezones,
        isFetching: isFetchingTimezones,
        error: errorTimezones
    } = useGetTimezonesQuery("");

    const handleOnSubmit = async (values: SurveyFormItems) => {
        const openAtDate = values.open_at
            ? timeZoneToUtc({ time: values.open_at, zone: values.timezone })
            : undefined;
        const closeAtDate = values.close_at
            ? timeZoneToUtc({ time: values.close_at, zone: values.timezone })
            : undefined;

        await performMutation({
            mutation: async () =>
                await addSurvey({
                    ...values,
                    open_at: openAtDate,
                    close_at: closeAtDate
                }).unwrap(),
            successMessage: "The survey was added successfully",
            errorMessage: "There was an error while updating the survey",
            formFieldsResetter: () => form.resetFields(),
            formFieldsSetter: (error: Error422) => {
                const valuesWithErrors = mapErrorsToFields({ error, values });
                form.setFields(valuesWithErrors);
            }
        });
    };

    const handleResetSuccess = () => reset();

    const error = errorTimezones;

    return (
        <div>
            <PageHeader onBack={() => navigate(`/`)} title="New survey" />
            <div className={styles.page}>
                {!isSuccess && !error && !isFetchingTimezones && (
                    <MultilingualForm
                        inputs={inputs({ timezones: timezones })}
                        form={form}
                        onSubmit={handleOnSubmit}
                        actions={[
                            <Button
                                key="submit"
                                type="primary"
                                htmlType="submit"
                                loading={isLoading}
                            >
                                Create new
                            </Button>
                        ]}
                    />
                )}
                {isSuccess && !error && (
                    <div className={styles.success}>
                        <div className={styles.title}>Successfully created the survey</div>
                        <div className={styles.actions}>
                            <Button type="primary" onClick={handleResetSuccess}>
                                Create new
                            </Button>
                            <Link to={`/survey/${data.id}`}>
                                <Button>Go to created survey</Button>
                            </Link>
                        </div>
                    </div>
                )}
                {error && <ErrorMessage message="There was an error, please try again later" />}
            </div>
        </div>
    );
};

export default NewSurvey;
