import { createAsyncThunk, createDraftSafeSelector, createSlice } from "@reduxjs/toolkit";
import type { RequestAction, RootState } from "../index";
import { clearToken, setToken } from "@utils/auth";
import axios from "@utils/axios";

export type LoginFormFields = { email: string; password: string };
export type IUserReducer = {
    identity?: any;
    token?: string;
    loading: boolean;
    error?: any;
    login: RequestAction;
    logout: RequestAction;
};

const initialState: IUserReducer = {
    identity: undefined,
    token: undefined,
    loading: false,
    error: undefined,
    login: {
        loading: false,
        error: undefined
    },
    logout: {
        loading: false,
        error: undefined
    }
};

export const logout = createAsyncThunk("user/logout", async (values, thunkApi) => {
    try {
        const {
            user: { token }
        } = thunkApi.getState() as RootState;

        const res = await axios.post(
            "/logout",
            {},
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );
        return res.data;
    } catch (error: any) {
        return thunkApi.rejectWithValue(error);
    }
});

export const login = createAsyncThunk<any, LoginFormFields>(
    "user/login",
    async (values, thunkApi) => {
        try {
            const res = await axios.post("/login", values);
            return res.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue(error);
        }
    }
);

export const getMe = createAsyncThunk<any>("user/getMe", async (values, thunkApi) => {
    try {
        const {
            user: { token }
        } = thunkApi.getState() as RootState;

        if (token) {
            const res = await axios.get("/admin/logged", {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            return res.data;
        }

        return thunkApi.rejectWithValue({
            code: 401
        });
    } catch (error: any) {
        return thunkApi.rejectWithValue(
            error.response
                ? {
                      code: error.response.status,
                      message: error.response.data.message
                  }
                : error.toString()
        );
    }
});

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        stickToken: (state, { payload }) => {
            state.token = payload;
        },
        clearUser: (state) => {
            state.token = undefined;
            state.identity = undefined;

            clearToken();
        },
        clearLoginErrors: (state) => {
            state.login.error = undefined;
        },
        clearLogoutErrors: (state) => {
            state.logout.error = undefined;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getMe.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(getMe.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.identity = payload;
        });

        builder.addCase(getMe.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload;
            state.token = undefined;

            clearToken();
        });

        builder.addCase(login.pending, (state) => {
            state.login.loading = true;
        });

        builder.addCase(login.fulfilled, (state, { payload }) => {
            state.login.loading = false;
            state.identity = payload.user;
            state.token = payload.token;
            setToken(payload.token);
        });

        builder.addCase(login.rejected, (state, { payload }) => {
            state.login.loading = false;
            state.login.error = payload;
        });

        builder.addCase(logout.pending, (state) => {
            state.logout.loading = true;
        });

        builder.addCase(logout.fulfilled, (state) => {
            state.logout.loading = false;
            state.identity = undefined;
            state.token = undefined;
            clearToken();
        });

        builder.addCase(logout.rejected, (state, { payload }) => {
            state.logout.loading = false;
            state.logout.error = payload;
        });
    }
});

const selectState = (state: RootState) => state;
const selectSelf = createDraftSafeSelector(selectState, (state) => state.user);

export const selectUser = createDraftSafeSelector(
    selectSelf,
    ({ identity, token, loading, error }) => ({
        identity,
        token,
        loading,
        error
    })
);

export const selectIsLogging = createDraftSafeSelector(selectSelf, (state) => state.login.loading);
export const selectIsLoggingErrors = createDraftSafeSelector(
    selectSelf,
    (state) => state.login.error
);

export const selectIsLogoutLoading = createDraftSafeSelector(
    selectSelf,
    (state) => state.logout.loading
);

export const selectIsLogoutError = createDraftSafeSelector(
    selectSelf,
    (state) => state.logout.error
);

export const { stickToken, clearLoginErrors, clearLogoutErrors, clearUser } = userSlice.actions;
export default userSlice.reducer;
