import { MultilingualFormInput } from "@components/forms/multilingual/index";

const detectInputs = (
    inputs: MultilingualFormInput[]
): [MultilingualFormInput[], MultilingualFormInput[]] => {
    const multilingualInputs: MultilingualFormInput[] = [];
    const withoutLang: MultilingualFormInput[] = [];

    inputs.forEach((input) => {
        if (input.multilingual !== undefined && !input.multilingual) {
            withoutLang.push(input);
        } else {
            multilingualInputs.push(input);
        }
    });

    return [multilingualInputs, withoutLang];
};

export default detectInputs;
