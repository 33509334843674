import { getDefaultLanguages } from "@utils/i18n";

type EntityToTest = any[] | { [key: string]: any };

export const isEmptyArray = (arrayToTest: EntityToTest) => {
    return Array.isArray(arrayToTest) && arrayToTest.length;
};

const objectMap = (obj: LanguageObject, fn: (value: string | null) => string) =>
    Object.fromEntries(Object.entries(obj).map(([k, v]) => [k, fn(v)]));

interface LanguageObject {
    [key: string]: string | null;
}
interface LanguageObjectPurified {
    [key: string]: string;
}

type ValueToTest = [] | null | LanguageObject;

export const processFormValue = (initialValue: ValueToTest): LanguageObjectPurified => {
    const languageObject = getDefaultLanguages().reduce((acc, curr) => {
        return { ...acc, [curr]: "" };
    }, {});
    if (Array.isArray(initialValue)) return languageObject;
    if (initialValue === null) return languageObject;
    else return objectMap(initialValue, (value) => (value === null ? "" : value));
};
