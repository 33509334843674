import React, { Ref, useState } from "react";
import { Form, FormInstance, Tabs } from "antd";
import { MultilingualFormProps } from ".";
import detectInputs from "./detect-inputs";
import Items from "@components/forms/multilingual/Items";

import styles from "./Form.module.scss";
import { getDefaultLanguages } from "@utils/i18n";

const MultilingualForm: React.ForwardRefExoticComponent<MultilingualFormProps> & {
    useForm: typeof Form.useForm;
} = React.forwardRef(
    ({ inputs, onSubmit, actions, form }: MultilingualFormProps, ref: Ref<FormInstance>) => {
        const [multilingualInputs, withoutLangInputs] = detectInputs(inputs);
        const [tabsWithErrors, changeTabsWithErrors] = useState<string[]>([]);
        const languages = getDefaultLanguages();

        const handleOnErrors = (errors: any) => {
            const state: string[] = [];
            errors.errorFields.forEach(({ name: [, lang] }: any) => {
                if (lang && !state.includes(lang)) {
                    state.push(lang);
                }
            });

            changeTabsWithErrors(state);
        };

        const handleOnSubmit = (values: any) => {
            changeTabsWithErrors([]);
            if (onSubmit) {
                onSubmit(values);
            }
        };

        return (
            <Form
                layout="vertical"
                form={form}
                ref={ref}
                onFinishFailed={handleOnErrors}
                onFinish={handleOnSubmit}
                className={styles.form}
            >
                <Tabs className={`header-without-padding ${styles.tabs}`} type="card">
                    {languages.map((lang) => (
                        <Tabs.TabPane
                            tab={
                                <div
                                    className={
                                        tabsWithErrors.includes(lang)
                                            ? styles.tabWithErrors
                                            : styles.tabWithoutErrors
                                    }
                                >
                                    {lang.toUpperCase()}
                                </div>
                            }
                            key={lang}
                            forceRender
                        >
                            <Items items={multilingualInputs} lang={lang} />
                        </Tabs.TabPane>
                    ))}
                </Tabs>
                <Items items={withoutLangInputs} />
                {actions && <Form.Item>{actions}</Form.Item>}
            </Form>
        );
    }
) as any;

MultilingualForm.useForm = Form.useForm;

export default MultilingualForm;
