import { Button, Popconfirm } from "antd";
import { Layout, Left, Right } from "@components/layout";
import { Link, Outlet } from "react-router-dom";
import { useDeleteSurveyMutation, useGetSurveysQuery } from "@store/api/surveys";
import { useNavigate, useParams } from "react-router";

import Card from "@components/Card/Card";
import { DeleteOutlined } from "@ant-design/icons";
import moment from "moment";
import { performMutation } from "@utils/perform-mutation";
import styles from "./Surveys.module.scss";
import { useState } from "react";

const Surveys = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);

    const {
        data: surveysData,
        isFetching: isFetchingSurveys,
        isLoading: isLoadingSurveys
    } = useGetSurveysQuery(currentPage);

    const [deleteSurvey, { isLoading: isLoadingDeletingSurvey }] = useDeleteSurveyMutation();

    const handleDeleteSurvey = async (pid: string) => {
        await performMutation({
            mutation: async () => await deleteSurvey(pid).unwrap(),
            onSuccessCallback: () => navigate("/"),
            successMessage: "The survey was deleted successfully",
            errorMessage: "There was an error while deleting the survey"
        });
    };

    const loading = isLoadingDeletingSurvey || isFetchingSurveys || isLoadingSurveys;

    return (
        <Layout>
            <Left
                action={
                    <Link to="/survey/new">
                        <Button className={styles.btn} type="primary" size="small">
                            + New
                        </Button>
                    </Link>
                }
                title="Surveys"
                loadingItems={loading}
                items={surveysData?.surveys || []}
                pagination={surveysData?.pagination}
                onPageChange={(page) => setCurrentPage(page)}
                renderItem={(survey) => (
                    <Link to={`/survey/${survey.id}`} data-testid="survey-card">
                        <Card
                            published={
                                survey.created_at ? moment(survey.created_at).fromNow() : undefined
                            }
                            updated={
                                survey.updated_at ? moment(survey.updated_at).fromNow() : undefined
                            }
                            title={survey.title?.it || survey.title?.en}
                            selected={id !== undefined && survey.id === id}
                            deleteElement={
                                <Popconfirm
                                    title="Are you sure to delete this survey?"
                                    okText="Delete"
                                    onConfirm={() => handleDeleteSurvey(survey.id)}
                                >
                                    <DeleteOutlined className={styles.delete} />
                                </Popconfirm>
                            }
                        />
                    </Link>
                )}
            />
            <Right>
                <Outlet />
            </Right>
        </Layout>
    );
};

export default Surveys;
