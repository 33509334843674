import { Error422 } from "@store/index";

interface mapErrorsToValuesArgs {
    error: Error422;
    values: { [key: string]: any };
}

export const mapErrorsToFields = ({
    error,
    values
}: mapErrorsToValuesArgs): {
    name: string;
    errors: string[];
}[] => {
    const errors = error.errors;
    const valuesArray = Object.keys(values).map((key) => {
        return {
            name: key,
            errors: errors[key]
        };
    });

    return valuesArray;
};
