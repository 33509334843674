import React from "react";
import { pipe } from "ramda";

import WithAuth from "./WithAuth";

const all = pipe(WithAuth);

const Hocs: React.FC = ({ children }) => <div>{children}</div>;

export default all(Hocs);
