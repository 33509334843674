import { Button, Dropdown, List, Menu } from "antd";
import { KeyName, MediaItem } from "@store/api/types";

import React from "react";
import { useParams } from "react-router-dom";

interface MediaListItemProps {
    association: MediaItem | undefined;
    item: KeyName;
    imageBackground: { [key: string]: string };
    setKeyNameToUpload: (str: string) => void;
    setImageBackground: (ps: (ps: BackgroundSignature) => BackgroundSignature) => void;
    detatchMedia: (mediaInfo: {
        surveyId: string;
        media_id: string;
        fieldname: string;
    }) => Promise<any>;
}

export interface BackgroundSignature {
    [key: string]: string;
}

const MediaListItem: React.FC<MediaListItemProps> = ({
    association,
    item,
    imageBackground,
    detatchMedia,
    setKeyNameToUpload,
    setImageBackground
}) => {
    const { id: surveyId } = useParams();

    return (
        <List.Item
            style={{ alignItems: "flex-start" }}
            data-testid="media-listitem"
            actions={[
                association ? (
                    <Button
                        onClick={async () =>
                            await detatchMedia({
                                surveyId: surveyId!,
                                media_id: association.id,
                                fieldname: item.keyname
                            })
                        }
                        data-testid="detach-button"
                    >
                        Detach
                    </Button>
                ) : (
                    <Button
                        type="primary"
                        onClick={() => setKeyNameToUpload(item.keyname)}
                        data-testid="attach-button"
                    >
                        Attach
                    </Button>
                )
            ]}
        >
            <List.Item.Meta
                title={item.description}
                description={
                    association ? (
                        <Dropdown
                            overlay={
                                <Menu>
                                    <Menu.Item
                                        key="0"
                                        onClick={() =>
                                            setImageBackground((ps: { [key: string]: string }) => ({
                                                ...ps,
                                                [item.keyname]: "black"
                                            }))
                                        }
                                    >
                                        Black
                                    </Menu.Item>
                                    <Menu.Item
                                        key="1"
                                        onClick={() =>
                                            setImageBackground((ps: { [key: string]: string }) => ({
                                                ...ps,
                                                [item.keyname]: "white"
                                            }))
                                        }
                                    >
                                        White
                                    </Menu.Item>
                                </Menu>
                            }
                        >
                            <span style={{ cursor: "pointer", textDecoration: "underline" }}>
                                Change image container background color
                            </span>
                        </Dropdown>
                    ) : undefined
                }
            />
            <div
                style={{
                    display: "flex",
                    backgroundColor: imageBackground[item.keyname] || "white",
                    width: "20%"
                }}
            >
                {association && (
                    <img
                        style={{ width: "100%" }}
                        src={association.url}
                        alt="media item"
                        data-testid="attached-image"
                    />
                )}
            </div>
        </List.Item>
    );
};

export default MediaListItem;
