import { Button, PageHeader, Spin, message } from "antd";
import { timeZoneToUtc, utcToTimeZone } from "@utils/timezones";
import {
    useGetSurveyQuery,
    useGetTimezonesQuery,
    useUpdateSurveyMutation
} from "@store/api/surveys";
import { useNavigate, useParams } from "react-router";

import { Error422 } from "@store/index";
import ErrorMessage from "@components/ErrorMessage";
import { Link } from "react-router-dom";
import MultilingualForm from "@components/forms/multilingual";
import { SurveyFormItems } from "./types";
import inputs from "./form/new-survey-inputs";
import { mapErrorsToFields } from "@utils/map-errors-to-fields";
import moment from "moment";
import { performMutation } from "@utils/perform-mutation";
import { processFormValue } from "@utils/general";
import styles from "./NewSurvey.module.scss";
import { useEffect } from "react";

const Details = () => {
    const dateFormat = "YYYY-MM-DD HH:mm";
    const { id } = useParams();
    const navigate = useNavigate();
    const [form] = MultilingualForm.useForm();

    const {
        data: survey,
        error: errorSurvey,
        isLoading: isLoadingSurvey,
        isFetching: isFetchingSurvey,
        refetch: refetchSurvey
    } = useGetSurveyQuery(id!);

    const {
        data: timezones,
        isLoading: isLoadingTimezones,
        isFetching: isFetchingTimezones,
        error: errorTimezones
    } = useGetTimezonesQuery("");

    const [updateSurvey, { isLoading: isLoadingUpdateSurvey }] = useUpdateSurveyMutation();

    useEffect(() => {
        if (errorSurvey) {
            message.error("Error on try to get survey details");
        }
        if (survey) {
            const openConverted = survey.open_at
                ? utcToTimeZone({ time: survey.open_at, zone: survey.timezone, format: dateFormat })
                : undefined;
            const closeConverted = survey.close_at
                ? utcToTimeZone({
                      time: survey.close_at,
                      zone: survey.timezone,
                      format: dateFormat
                  })
                : undefined;

            form.setFieldsValue({
                ...survey,
                description: processFormValue(survey.description),
                closed_message: processFormValue(survey.closed_message),
                pre_message: processFormValue(survey.pre_message),
                post_message: processFormValue(survey.post_message),
                close_at: closeConverted ? moment(closeConverted) : undefined,
                open_at: openConverted ? moment(openConverted) : undefined,
                is_active: !!survey.is_active
            });
        }
    }, [form, survey, errorSurvey]);

    const handleOnSubmit = async (values: SurveyFormItems) => {
        const openAtDate = values.open_at
            ? timeZoneToUtc({ time: values.open_at, zone: values.timezone })
            : null;
        const closeAtDate = values.close_at
            ? timeZoneToUtc({ time: values.close_at, zone: values.timezone })
            : null;

        await performMutation({
            mutation: async () =>
                await updateSurvey({
                    id,
                    ...values,
                    timezone: values.timezone,
                    open_at: openAtDate,
                    close_at: closeAtDate
                }).unwrap(),
            successMessage: "The survey was updated successfully",
            errorMessage: "There was an error while updating the survey",
            onSuccessCallback: () => refetchSurvey(),
            formFieldsResetter: () => form.resetFields(),
            formFieldsSetter: (error: Error422) => {
                const valuesWithErrors = mapErrorsToFields({ error, values });
                form.setFields(valuesWithErrors);
            }
        });
    };

    const error = errorSurvey || errorTimezones;
    const loading =
        isLoadingUpdateSurvey || isLoadingSurvey || isFetchingSurvey || isLoadingTimezones;

    return (
        <Spin spinning={loading}>
            <PageHeader
                onBack={() => navigate(`/`)}
                title="Update survey"
                extra={[
                    <Link key="questions" to={`/survey/${id}/questions`}>
                        <Button>Questions</Button>
                    </Link>,
                    <Link key="answers" to={`/survey/${id}/answers`}>
                        <Button>Answers</Button>
                    </Link>,
                    <Link key="styles" to={`/survey/${id}/styles`}>
                        <Button>Styles</Button>
                    </Link>,
                    <Link key="files-and images" to={`/survey/${id}/files-and-images`}>
                        <Button>Files and Images</Button>
                    </Link>
                ]}
            />
            <div className={styles.page}>
                {!isFetchingTimezones && !error && (
                    <MultilingualForm
                        form={form}
                        inputs={inputs({ timezones })}
                        onSubmit={handleOnSubmit}
                        actions={[
                            <Button key="submit" type="primary" htmlType="submit">
                                Update survey
                            </Button>
                        ]}
                    />
                )}
                {error && <ErrorMessage message="There was an error, please try again later" />}
            </div>
        </Spin>
    );
};

export default Details;
