import { MultilingualFormInput } from "@components/forms/multilingual";
import { SurveySlotType } from "@store/api/types";
import inputs from "./intputs";

export const questionInputs = ({
    futureSlots
}: {
    futureSlots: SurveySlotType[];
}): MultilingualFormInput[] => {
    return [
        ...inputs,
        {
            name: "description",
            label: "Description",
            type: "rich-text-editor"
        },
        {
            name: "is_radio",
            label: "Is Radio button?",
            type: "switch",
            multilingual: false
        },
        {
            name: "is_icon",
            label: "Is Icon?",
            type: "switch",
            multilingual: false
        },
        {
            name: "min_number_of_answers",
            label: "Min number of answers",
            multilingual: false
        },
        {
            name: "max_number_of_answers",
            label: "Max number of answers",
            multilingual: false
        },
        {
            name: "survey_slot_ids",
            label: "Future (and not yet published) question groups in which the question is published",
            type: "select",
            multilingual: false,
            inputProps: {
                mode: "multiple",
                options: futureSlots.map((slot) => {
                    return {
                        label: slot.title!,
                        value: slot.id
                    };
                })
            }
        }
    ];
};
