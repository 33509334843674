import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Dropdown, Layout, Menu, message, Spin } from "antd";
import { SettingOutlined, UserDeleteOutlined } from "@ant-design/icons";
import {
    clearLogoutErrors,
    clearUser,
    selectIsLogoutError,
    selectIsLogoutLoading
} from "@store/slices/user";
import styles from "./App.module.scss";

const { Header, Content } = Layout;

const App: React.FC = () => {
    const dispatch = useDispatch();
    const logoutLoading = useSelector(selectIsLogoutLoading);
    const logoutError = useSelector(selectIsLogoutError);

    useEffect(() => {
        if (logoutError) {
            message.error(logoutError.message).then(() => dispatch(clearLogoutErrors()));
        }
    }, [logoutError, dispatch]);

    const handleLogOut = () => dispatch(clearUser());

    return (
        <div data-testid="app" className={styles.app}>
            <Layout>
                <Header className={styles.header}>
                    <div className={styles.left}>
                        <div className={styles.logo}>
                            <img src="/logo-without-text.png" alt="Isplora" />
                        </div>
                    </div>
                    <div className={styles.right}>
                        <Dropdown
                            overlay={
                                <Menu>
                                    <Menu.Item key="settings" icon={<SettingOutlined />}>
                                        Settings
                                    </Menu.Item>
                                    <Menu.Divider />
                                    <Menu.Item
                                        key="logout"
                                        onClick={handleLogOut}
                                        icon={<UserDeleteOutlined />}
                                        data-testid="logout"
                                    >
                                        <Spin key="logout" spinning={logoutLoading}>
                                            Log out
                                        </Spin>
                                    </Menu.Item>
                                </Menu>
                            }
                            trigger={["click"]}
                            placement="bottomRight"
                        >
                            <Avatar
                                data-testid="header-user-avatar"
                                className={styles.avatar}
                                src="https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=80&q=80"
                            />
                        </Dropdown>
                    </div>
                </Header>
                <Content>
                    <Outlet />
                </Content>
            </Layout>
        </div>
    );
};

export default App;
