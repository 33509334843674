import React from "react";
import { Spin } from "antd";

import styles from "./FullPageSpin.module.scss";

const FullPageSpin: React.FC = () => {
    return (
        <Spin className={styles.spin}>
            <div />
        </Spin>
    );
};

export default FullPageSpin;
