import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { Button, Col, Form, Input, Row } from "antd";
import {
    clearLoginErrors,
    login,
    LoginFormFields,
    selectIsLogging,
    selectIsLoggingErrors,
    selectUser
} from "@store/slices/user";

import styles from "./Login.module.scss";

const Login: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { identity } = useSelector(selectUser);
    const error = useSelector(selectIsLoggingErrors);
    const loading = useSelector(selectIsLogging);
    const [form] = Form.useForm();

    useEffect(() => {
        if (identity) {
            navigate(`/${location.search}`);
        }
    }, [identity, location.search, navigate]);

    useEffect(() => {
        if (error) {
            form.setFields([
                {
                    name: "email",
                    errors: [""]
                },
                {
                    name: "password",
                    errors: [error.status === 422 ? error.message : ""]
                }
            ]);

            dispatch(clearLoginErrors());
        }
    }, [error, form, dispatch]);

    const handleSubmitForm = (values: LoginFormFields) => dispatch(login(values));

    return (
        <div className={styles.login}>
            <Row className={styles.row}>
                <Col lg={8} md={6} sm={0} xl={8} xxl={9}>
                    <div className={styles.fakeHeight} />
                </Col>
                <Col lg={8} md={12} sm={24} xl={8} xxl={6} className={styles.col}>
                    <div className={styles.formCard}>
                        <div className={styles.form}>
                            <Form form={form} onFinish={handleSubmitForm} data-testid="login-form">
                                <Form.Item
                                    name="email"
                                    rules={[{ required: true, message: "Insert your email" }]}
                                >
                                    <Input placeholder="Type your email" />
                                </Form.Item>
                                <Form.Item
                                    name="password"
                                    rules={[
                                        { required: true, message: "Please input your password!" }
                                    ]}
                                >
                                    <Input.Password placeholder="Type your password" />
                                </Form.Item>
                                <Form.Item>
                                    <Button
                                        loading={loading}
                                        htmlType="submit"
                                        className={styles.btn}
                                        type="primary"
                                    >
                                        Log in
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </Col>
                <Col lg={8} md={6} sm={0} xl={8} xxl={9}>
                    <div className={styles.fakeHeight} />
                </Col>
            </Row>
        </div>
    );
};

export default Login;
