import { FormInstance } from "antd";

export const onFormChangeValue =
    ({ form }: { form: FormInstance }) =>
    (changedFields: any) => {
        const publishImmediatelyChange = changedFields.find((changedFiled: any) => {
            return changedFiled.name?.[0] === "publish_immediately";
        });
        if (publishImmediatelyChange) {
            form.setFields([
                {
                    name: "start_at",
                    value: null,
                    errors: []
                }
            ]);
        }
    };
