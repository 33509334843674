import { Button, Dropdown, Form, PageHeader, Select, Space, Table, message } from "antd";
import { ColumnsType, TablePaginationConfig } from "antd/lib/table/interface";
import React, { useEffect, useState } from "react";
import { SurveyOptionType, SurveyQuestionType } from "@store/api/types";
import { useGetSurveyAnswersQuery, useGetSurveySlotsQuery } from "@store/api/surveys";
import { useNavigate, useParams } from "react-router";

import { CloudDownloadOutlined } from "@ant-design/icons";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { Page } from "@components/layout";
import axios from "axios";
import moment from "moment";
import { saveAs } from "file-saver";
import { selectUser } from "@store/slices/user";
import { useSelector } from "react-redux";

interface DataType {
    id: string;
    signature: number;
    question: any;
    option: any;
    updated_at: string;
}

const columns: ColumnsType<DataType> = [
    {
        key: "question",
        title: "Question",
        dataIndex: "question",
        render: (question: SurveyQuestionType) => (
            <div data-testid="answer-question-title">{question.title_default}</div>
        )
    },
    {
        key: "options",
        title: "Options",
        dataIndex: "options",
        render: (options: SurveyOptionType[]) =>
            options?.map((option: SurveyOptionType) => <div>{option.title_default}</div>)
    },
    {
        key: "signature",
        title: "Signature",
        dataIndex: "signature"
    },
    {
        key: "survey_slot_id",
        title: "Slot ID",
        dataIndex: "survey_slot_id"
    },
    {
        key: "created_at",
        title: "Date",
        dataIndex: "created_at",
        render: (data) => moment(data).format("DD/MM/YYYY HH:mm:ss")
    }
];

const DEFAULT_PAGE = 1;

const Answers: React.FC = () => {
    const navigate = useNavigate();
    const { id, slot } = useParams();
    const { token } = useSelector(selectUser);
    const [currentPage, setCurrentPage] = useState<number>(DEFAULT_PAGE);
    const [downloadingExport, toggleDownloadingExport] = useState<boolean>(false);
    const [form] = Form.useForm();

    const {
        data: surveySlots,
        isLoading: isLoadingSurveySlots,
        isFetching: isFetchingSurveySlot,
        error: errorSurveySlots
    } = useGetSurveySlotsQuery({ surveyId: id! });

    const isValidSlot = !!slot && !!surveySlots?.find((slt) => slt.id === parseInt(slot));

    const {
        data: answersData,
        isFetching: isFetchingAnswersData,
        isLoading: isLoadingAnswersData,
        error: errorAnswersData
    } = useGetSurveyAnswersQuery({
        page: currentPage,
        surveyId: id,
        surveySlot: isValidSlot ? slot : null
    });

    const handleDownloadFile = async () => {
        if (!downloadingExport) {
            toggleDownloadingExport(true);

            const url = isValidSlot
                ? `${process.env.REACT_APP_API_BASE_URL}/admin/surveys/${id}/export?survey_slot_id=${slot}`
                : `${process.env.REACT_APP_API_BASE_URL}/admin/surveys/${id}/export`;

            try {
                const res = await axios.get(url, {
                    headers: { Authorization: `Bearer ${token}` },
                    responseType: "blob"
                });
                const blob = new Blob([res.data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                });
                const fileName = isValidSlot ? `${id}-slot-${slot}` : id;
                saveAs(blob, fileName);
            } catch (error: any) {
                message.error(error.message);
            }
            toggleDownloadingExport(false);
        }
    };

    const handleTableChange = (pagination: TablePaginationConfig) => {
        setCurrentPage(pagination.current || DEFAULT_PAGE);
    };

    useEffect(() => {
        if (form && surveySlots) {
            if (isValidSlot) {
                form.setFieldsValue({
                    slot: parseInt(slot)
                });
            } else navigate(`/survey/${id}/answers`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form, surveySlots, isValidSlot]);

    const error = errorSurveySlots || errorAnswersData;
    const loading =
        isLoadingSurveySlots ||
        isFetchingSurveySlot ||
        isLoadingAnswersData ||
        isFetchingAnswersData;

    if (error) return <ErrorMessage message="There was an error, please try again later" />;
    return (
        <div>
            <PageHeader
                onBack={() => navigate(`/survey/${id}`)}
                title="Survey answers"
                extra={[
                    <Space direction="horizontal" align="end" key="export">
                        <Button
                            loading={downloadingExport}
                            onClick={handleDownloadFile}
                            icon={<CloudDownloadOutlined />}
                            disabled={false}
                            data-testid="export-answers-button"
                        >
                            {isValidSlot
                                ? "Export answers of the selected slot"
                                : "Export all answers"}
                        </Button>
                        {surveySlots && (
                            <Form form={form} layout="vertical">
                                <Form.Item style={{ margin: 0 }} label="Slot selection" name="slot">
                                    <Select
                                        placeholder="Select the slot"
                                        style={{ width: 200 }}
                                        allowClear
                                        options={surveySlots.map((slot) => {
                                            return {
                                                label: slot.title,
                                                value: slot.id
                                            };
                                        })}
                                        onChange={(value) =>
                                            typeof value === "number"
                                                ? navigate(`/survey/${id}/answers/${value}`)
                                                : navigate(`/survey/${id}/answers`)
                                        }
                                    />
                                </Form.Item>
                            </Form>
                        )}
                    </Space>
                ]}
            />
            <Page>
                {!isLoadingAnswersData && (
                    <Table
                        loading={loading}
                        bordered
                        rowKey="id"
                        dataSource={answersData?.answers || []}
                        columns={columns}
                        onChange={handleTableChange}
                        pagination={{
                            total: answersData?.pagination.total || 0,
                            defaultCurrent: currentPage,
                            pageSize: answersData?.pagination.perPage || 0
                        }}
                    />
                )}
            </Page>
        </div>
    );
};

export default Answers;
