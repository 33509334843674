import { Rule } from "rc-field-form/lib/interface";
import Form from "./MultilingualForm";
import React, { Ref } from "react";
import { ColProps } from "antd/lib/col";
import { FormInstance } from "antd";

export type MultilingualFormInputTypes =
    | "text"
    | "number"
    | "password"
    | "textarea"
    | "select"
    | "switch"
    | "date-picker"
    | "range-picker"
    | "rich-text-editor";

export interface SelectOption {
    label: string;
    value: string;
}

export interface MultilingualFormInput {
    name: string;
    label?: string;
    rules?: Rule[];
    type?: MultilingualFormInputTypes;
    placeholder?: string;
    multilingual?: boolean;
    mode?: "multiple" | "tags";
    options?: SelectOption[];
    grid?: {
        col: ColProps;
    };
    inputProps?: { [key: string]: any };
}

export interface MultilingualFormProps {
    form?: any;
    onSubmit: (values: any) => void;
    inputs: MultilingualFormInput[];
    actions?: React.ReactElement[];
    ref?: Ref<FormInstance>;
}

export default Form;
