import React from "react";
import { Link } from "react-router-dom";
import { Button, Result } from "antd";

interface ErrorMessageProps {
    message: string;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ message }) => {
    return (
        <Result
            status="error"
            title={"Error"}
            subTitle={message}
            extra={[
                <Button type="primary">
                    <Link to="/">Go to the homepage</Link>
                </Button>
            ]}
        ></Result>
    );
};

export default ErrorMessage;
