import { Button, PageHeader, Spin } from "antd";
import { useAddSurveyQuestionMutation, useGetSurveySlotsQuery } from "@store/api/surveys";
import { useNavigate, useParams } from "react-router";

import { Error422 } from "@store/index";
import ErrorMessage from "@components/ErrorMessage";
import MultilingualForm from "@components/forms/multilingual";
import React from "react";
import { SurveyQuestionFormItems } from "../types";
import { mapErrorsToFields } from "@utils/map-errors-to-fields";
import { performMutation } from "@utils/perform-mutation";
import { questionInputs } from "../form/new-question-inputs";
import styles from "../NewSurvey.module.scss";

const NewQuestion: React.FC = () => {
    const { id: surveyId } = useParams();
    const navigate = useNavigate();
    const [form] = MultilingualForm.useForm();

    const {
        data: futureSlots,
        isLoading: isLoadingSlots,
        isFetching: isFetchingSlots,
        error: errorSlots,
        isUninitialized: isUninitializedSlots
    } = useGetSurveySlotsQuery(
        { surveyId: surveyId!, unpublishedOnly: true },
        { skip: !!!surveyId }
    );

    const [addQuestion, { isLoading: isLoadingAddQuestion }] = useAddSurveyQuestionMutation();

    const handleOnSubmit = async (values: SurveyQuestionFormItems) => {
        await performMutation({
            mutation: async () => await addQuestion({ ...values, surveyId }).unwrap(),
            successMessage: "The question was added successfully",
            errorMessage: "There was an error while creating the question",
            onSuccessCallback: () => navigate(`/survey/${surveyId}/questions`),
            formFieldsResetter: () => form.resetFields(),
            formFieldsSetter: (error: Error422) => {
                const valuesWithErrors = mapErrorsToFields({ error, values });
                form.setFields(valuesWithErrors);
            }
        });
    };

    const error = errorSlots;
    const loading =
        isLoadingAddQuestion || isLoadingSlots || isFetchingSlots || isUninitializedSlots;

    if (error) return <ErrorMessage message="There was an error, please try again later" />;
    return (
        <Spin spinning={loading}>
            <PageHeader title="New survey question" />
            <div className={styles.page}>
                <MultilingualForm
                    form={form}
                    inputs={questionInputs({ futureSlots: futureSlots || [] })}
                    onSubmit={handleOnSubmit}
                    actions={[
                        <Button
                            key="submit"
                            type="primary"
                            htmlType="submit"
                            data-testid="new-question-submit"
                        >
                            Create new
                        </Button>
                    ]}
                />
            </div>
        </Spin>
    );
};

export default NewQuestion;
