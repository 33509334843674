import { configureStore } from "@reduxjs/toolkit";
import { createLogger } from "redux-logger";
import surveysApi from "@store/api/surveys";
import userReducer from "./slices/user";

const logger = createLogger({ collapsed: true });

const store = configureStore({
    reducer: {
        user: userReducer,
        [surveysApi.reducerPath]: surveysApi.reducer
    },
    middleware: (getDefaultMiddleware) => {
        let middleware = getDefaultMiddleware();

        if (process.env.NODE_ENV === "development") {
            middleware = middleware.concat(logger);
        }

        middleware = middleware.concat(surveysApi.middleware);

        return middleware;
    }
});

export default store;
export interface RequestAction {
    loading: boolean;
    success?: boolean;
    item?: any;
    error: any;
}
export interface Error422 {
    errors: {
        [key: string]: string[];
    };
    message: string;
}
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
