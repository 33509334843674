import { Button, Drawer, Spin } from "antd";
import React, { useEffect, useState } from "react";
import {
    useGetQuestionOptionQuery,
    useUpdateSurveyQuestionOptionMutation
} from "@store/api/surveys";
import { useNavigate, useParams } from "react-router";

import MultilingualForm from "@components/forms/multilingual";
import { SurveyQuestionOptionFormItems } from "./types";
import { optionInputs } from "./form/new-option-inputs";
import { performMutation } from "@utils/perform-mutation";

const UpdateSurveyQuestionOption: React.FC = () => {
    const navigate = useNavigate();
    const [form] = MultilingualForm.useForm();
    const { id, questionId, optionId } = useParams();
    const [visible, setVisible] = useState<boolean>(false);

    const {
        data: questionOption,
        isLoading: isLoadingQuestionOption,
        isFetching: isFetchingQuestionOption,
        refetch: refetchQuestionOption
    } = useGetQuestionOptionQuery(optionId);

    const [updateOption, { isLoading: isLoadingUpdateOption }] =
        useUpdateSurveyQuestionOptionMutation();

    useEffect(() => {
        setVisible(true);
    }, []);

    useEffect(() => {
        if (questionOption && form) {
            form.setFieldsValue(questionOption);
        }
    }, [questionOption, form]);

    const handleCloseDrawer = () => {
        setVisible(false);
        setTimeout(() => {
            navigate(`/survey/${id}/questions/${questionId}/options`);
        }, 400);
    };

    const handleOnSubmit = async (values: SurveyQuestionOptionFormItems) => {
        await performMutation({
            mutation: async () =>
                await updateOption({
                    optionId,
                    ...values
                }).unwrap(),
            successMessage: "The question option was updated successfully",
            errorMessage: "There was an error while updating the question option",
            onSuccessCallback: () => {
                refetchQuestionOption();
                handleCloseDrawer();
            }
        });
    };

    const loading = isLoadingQuestionOption || isFetchingQuestionOption || isLoadingUpdateOption;
    return (
        <Drawer width={550} visible={visible} onClose={handleCloseDrawer} title="Update option">
            <Spin spinning={loading}>
                <MultilingualForm
                    form={form}
                    inputs={optionInputs}
                    onSubmit={handleOnSubmit}
                    actions={[
                        <Button key="submit" type="primary" htmlType="submit">
                            Update
                        </Button>
                    ]}
                />
            </Spin>
        </Drawer>
    );
};

export default UpdateSurveyQuestionOption;
