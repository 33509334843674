import {
    Alert,
    Button,
    DatePicker,
    Divider,
    Form,
    Input,
    List,
    PageHeader,
    Select,
    Space,
    Switch
} from "antd";
import { BulbOutlined, PlusOutlined } from "@ant-design/icons";
import { Layout, Left, Right } from "@components/layout";
import React, { useEffect, useMemo, useState } from "react";
import { checkDate, checkFinishDate } from "./checkDate";
import { timeZoneToUtc, utcToTimeZone } from "@utils/timezones";
import {
    useCreateSurveySlotMutation,
    useDeleteSurveySlotMutation,
    useGetSurveyQuery,
    useGetSurveyQuestionsQuery,
    useGetSurveySlotsQuery,
    usePublishSurveySlotMutation,
    useUpdateSurveySlotMutation
} from "@store/api/surveys";
import { useNavigate, useParams } from "react-router";

import Card from "@components/Card";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { Link } from "react-router-dom";
import SurveySlotCard from "@components/SurveySlotCard/SurveySlotCard";
import { SurveySlotType } from "@store/api/types";
import moment from "moment";
import momentTimezone from "moment-timezone";
import { onFormChangeValue } from "./onFormChangeValue";
import { performMutation } from "../../../utils/perform-mutation";
import { produce } from "immer";
import { slotTitleOptions } from "./slotTitleOptions";

export enum AdditionalSlotFields {
    title = "title",
    startAt = "start_at",
    finishAt = "finish_at",
    publishImmediately = "publish_immediately"
}

const Slots: React.FC = () => {
    const navigate = useNavigate();
    const { id: surveyId, slotId } = useParams();
    const addNewSlot = slotId === "add-new";
    const [isPublishImmediately, setIsPublishedimmediately] = useState<boolean>(false);

    const [form] = Form.useForm();

    const [createSlot, { isLoading: isLoadingCreateSlot }] = useCreateSurveySlotMutation();
    const [updateSlot, { isLoading: isLoadingUpdateSlot }] = useUpdateSurveySlotMutation();
    const [deleteSlot, { isLoading: isLoadingDeleteSlot }] = useDeleteSurveySlotMutation();
    const [publishSlot, { isLoading: isLoadingPublishSlot }] = usePublishSurveySlotMutation();

    const {
        data: slots,
        isLoading: isLoadingSlots,
        isFetching: isFetchingSlots,
        error: errorSlots,
        isUninitialized: isUninitializedSlots
    } = useGetSurveySlotsQuery({ surveyId: surveyId! }, { skip: !!!surveyId });

    const {
        data: survey,
        error: errorSurvey,
        isLoading: isLoadingSurvey,
        isFetching: isFetchingSurvey,
        refetch: refetchSurvey
    } = useGetSurveyQuery(surveyId!);

    const {
        data: questions,
        isFetching: isFetchingQuestions,
        isLoading: isLoadingQuestions,
        error: errorQuestions
    } = useGetSurveyQuestionsQuery({ surveyId: surveyId! });

    const selectedSlot = useMemo(() => {
        if (slotId && slots) return slots.find((slot) => String(slot.id) === slotId);
        else return undefined;
    }, [slotId, slots]);

    useEffect(() => {
        setIsPublishedimmediately(false);
    }, [selectedSlot, form]);

    useEffect(() => {
        if (addNewSlot && questions) {
            form.resetFields();
            form.setFields(
                questions?.map((question) => {
                    return {
                        name: question.id,
                        value: false
                    };
                })
            );
        } else if (selectedSlot && form && questions) {
            form.resetFields();
            form.setFieldsValue({
                ...selectedSlot,
                start_at: selectedSlot.start_at
                    ? moment(utcToTimeZone({ time: selectedSlot.start_at, zone: survey?.timezone }))
                    : undefined,
                finish_at: selectedSlot.finish_at
                    ? moment(
                          utcToTimeZone({ time: selectedSlot.finish_at, zone: survey?.timezone })
                      )
                    : undefined
            });
            form.setFields(
                questions?.map((question) => {
                    return {
                        name: question.id,
                        value: !!selectedSlot.questions_config.includes(question.id)
                    };
                })
            );
        }
    }, [selectedSlot, addNewSlot, slots, questions, form, survey?.timezone]);

    const isGeneralEditingDisabled = selectedSlot && !!selectedSlot.published_at;
    const isFinishedAtDisabled = selectedSlot?.finish_at
        ? new Date(selectedSlot.finish_at) <=
          new Date(
              momentTimezone
                  .tz(
                      moment()?.set({ second: 0 }).toISOString(),
                      "YYYY-MM-DD HH:mm:ss",
                      survey?.timezone!
                  )
                  .format("YYYY-MM-DD HH:mm:ss")
          )
        : false;

    const handleCUDSlot =
        (method: "post" | "put" | "delete", slotId?: string | number) =>
        async (values?: { [key: string]: boolean | string }) => {
            const slotValues = values || {};

            if (method !== "delete") {
                if (isGeneralEditingDisabled && !isFinishedAtDisabled) {
                    const isDateValid = checkFinishDate({
                        finishDate: slotValues.finish_at as string,
                        surveyTimezone: survey?.timezone,
                        form: form
                    });

                    if (isDateValid === false) return;
                } else {
                    const isDateValid = checkDate({
                        startDate: slotValues.start_at as string,
                        finishDate: slotValues.finish_at as string,
                        surveyTimezone: survey?.timezone,
                        form: form
                    });

                    if (isDateValid === false) return;
                }
            }

            await performMutation({
                mutation: async () =>
                    method === "post"
                        ? await createSlot({
                              surveyId: surveyId!,
                              title: slotValues.title as string,
                              configuration: produce(slotValues, (draft) => {
                                  delete draft[AdditionalSlotFields.title];
                                  delete draft[AdditionalSlotFields.startAt];
                                  delete draft[AdditionalSlotFields.finishAt];
                                  delete draft[AdditionalSlotFields.publishImmediately];
                              }) as { [key: string]: boolean },
                              start_at:
                                  slotValues.start_at &&
                                  !!!slotValues[AdditionalSlotFields.publishImmediately]
                                      ? timeZoneToUtc({
                                            time: slotValues.start_at as string,
                                            zone: survey?.timezone!
                                        })
                                      : null,
                              finish_at: slotValues.finish_at
                                  ? timeZoneToUtc({
                                        time: slotValues.finish_at as string,
                                        zone: survey?.timezone!
                                    })
                                  : null
                          }).unwrap()
                        : method === "put"
                        ? await updateSlot({
                              surveyId: surveyId!,
                              title: slotValues.title as string,
                              slotId: slotId!,
                              configuration: produce(slotValues, (draft) => {
                                  delete draft[AdditionalSlotFields.title];
                                  delete draft[AdditionalSlotFields.startAt];
                                  delete draft[AdditionalSlotFields.finishAt];
                                  delete draft[AdditionalSlotFields.publishImmediately];
                              }) as { [key: string]: boolean },
                              start_at:
                                  slotValues.start_at &&
                                  !!!slotValues[AdditionalSlotFields.publishImmediately]
                                      ? timeZoneToUtc({
                                            time: slotValues.start_at as string,
                                            zone: survey?.timezone!
                                        })
                                      : null,
                              finish_at: slotValues.finish_at
                                  ? timeZoneToUtc({
                                        time: slotValues.finish_at as string,
                                        zone: survey?.timezone!
                                    })
                                  : null
                          }).unwrap()
                        : await deleteSlot({
                              surveyId: surveyId!,
                              slotId: slotId!
                          }).unwrap(),
                successMessage:
                    "The question-group-related operation has been performed successfully",
                errorMessage:
                    "There was an error in performing the question-group-related request. Please try again later",
                onSuccessCallback: () => {
                    navigate(`/survey/${surveyId}/slots`);
                    setIsPublishedimmediately(false);
                    refetchSurvey();
                }
            });
        };

    const handlePublishSlot = async (slotId: number, keepOldSchedule: boolean) => {
        await performMutation({
            mutation: async () =>
                publishSlot({
                    surveyId: surveyId!,
                    slotId: slotId,
                    keep_old_schedule: keepOldSchedule
                }).unwrap(),
            successMessage: "The question-group has been published successfully",
            errorMessage: "There was an error while publishing the question-group",
            onSuccessCallback: () => {
                refetchSurvey();
                navigate(`/survey/${surveyId}/slots`);
            }
        });
    };

    const error = errorSlots || errorSurvey || errorQuestions;
    const loading =
        isLoadingSlots ||
        isFetchingSlots ||
        isLoadingCreateSlot ||
        isLoadingSurvey ||
        isFetchingQuestions ||
        isLoadingQuestions ||
        isFetchingSurvey ||
        isLoadingUpdateSlot ||
        isUninitializedSlots ||
        isLoadingDeleteSlot ||
        isLoadingPublishSlot;

    if (error) return <ErrorMessage message="There was an error, please try again later" />;
    return (
        <>
            <PageHeader
                onBack={() => navigate(`/survey/${surveyId}`)}
                title={<Space direction="horizontal">Survey question groups</Space>}
                extra={[
                    <Link key="question-groups" to={`/survey/${surveyId}/questions`}>
                        <Button>Questions</Button>
                    </Link>
                ]}
            />
            <Layout height="calc(100vh - 150px)">
                <Left
                    height="calc(100vh - 150px)"
                    loadingItems={loading}
                    action={
                        <Button
                            icon={<PlusOutlined />}
                            onClick={() => navigate(`/survey/${surveyId}/slots/add-new`)}
                            type="primary"
                            disabled={addNewSlot || !!!questions?.length}
                            size="small"
                        >
                            Add new
                        </Button>
                    }
                    withSearch={false}
                    title="Question groups"
                    items={slots || []}
                    renderItem={(slot: SurveySlotType) => (
                        <SurveySlotCard
                            slot={slot}
                            handleCUDSlot={handleCUDSlot}
                            survey={survey}
                            handlePublishSlot={handlePublishSlot}
                        />
                    )}
                />
                <Right height="calc(100vh - 150px)">
                    {!!questions?.length && (selectedSlot || addNewSlot) ? (
                        <>
                            <PageHeader
                                title={addNewSlot ? "Add new question group" : selectedSlot?.title}
                            />
                            <div style={{ padding: "0 24px 24px" }}>
                                <Form
                                    layout="vertical"
                                    labelWrap
                                    scrollToFirstError={{ behavior: "smooth", block: "center" }}
                                    form={form}
                                    onFinish={
                                        addNewSlot
                                            ? handleCUDSlot("post")
                                            : handleCUDSlot("put", slotId)
                                    }
                                    onFieldsChange={onFormChangeValue({ form })}
                                >
                                    <Form.Item
                                        name={AdditionalSlotFields.title}
                                        label={"Title of the question group"}
                                        labelCol={{ span: 24 }}
                                        rules={[
                                            {
                                                required: true,
                                                message: "This field is required"
                                            }
                                        ]}
                                    >
                                        <Input type="text" disabled={isGeneralEditingDisabled} />
                                    </Form.Item>
                                    <Alert
                                        message={`This survey is set to the following timezone: ${survey?.timezone}`}
                                        style={{ marginBottom: 20 }}
                                    />
                                    <Form.Item
                                        name={AdditionalSlotFields.publishImmediately}
                                        label={"Publish immediately"}
                                        labelCol={{ span: 24 }}
                                    >
                                        <Switch
                                            checked={isPublishImmediately}
                                            onChange={(checked) =>
                                                setIsPublishedimmediately(checked)
                                            }
                                            disabled={isGeneralEditingDisabled}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name={AdditionalSlotFields.startAt}
                                        label={"Start at"}
                                        labelCol={{ span: 24 }}
                                        rules={
                                            !isPublishImmediately
                                                ? [
                                                      {
                                                          required: true,
                                                          message: "This field is required"
                                                      }
                                                  ]
                                                : []
                                        }
                                    >
                                        <DatePicker
                                            showTime={{ format: "HH:mm" }}
                                            format={"DD-MM-YYYY HH:mm"}
                                            placeholder=""
                                            disabled={
                                                isGeneralEditingDisabled || isPublishImmediately
                                            }
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name={AdditionalSlotFields.finishAt}
                                        label={"Finish at"}
                                        labelCol={{ span: 24 }}
                                    >
                                        <DatePicker
                                            showTime={{ format: "HH:mm" }}
                                            format={"DD-MM-YYYY HH:mm"}
                                            placeholder=""
                                            disabled={isFinishedAtDisabled}
                                        />
                                    </Form.Item>
                                    <Divider />
                                    <List
                                        dataSource={questions || []}
                                        renderItem={(question) => {
                                            return (
                                                <Card title={""}>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "space-between"
                                                        }}
                                                    >
                                                        {question?.title?.en || ""}
                                                        <Form.Item
                                                            name={question.id}
                                                            label={"Publish"}
                                                            style={{ margin: "0 10px 10px" }}
                                                            valuePropName="checked"
                                                        >
                                                            <Switch
                                                                checkedChildren={"Yes"}
                                                                unCheckedChildren={"No"}
                                                                disabled={isGeneralEditingDisabled}
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                </Card>
                                            );
                                        }}
                                    />
                                    <Divider />
                                    <Button
                                        key="submit"
                                        type="primary"
                                        htmlType="submit"
                                        data-testid="slot-submit"
                                        disabled={isGeneralEditingDisabled && isFinishedAtDisabled}
                                        loading={loading}
                                    >
                                        {addNewSlot ? "Create new" : "Edit question group"}
                                    </Button>
                                </Form>
                            </div>
                        </>
                    ) : (
                        <>
                            <PageHeader title={"Question group details"} />
                            <div style={{ padding: "0 24px 24px" }}>
                                <Alert
                                    message={"Select a question group or create a new one"}
                                    type="info"
                                    icon={<BulbOutlined />}
                                    showIcon
                                ></Alert>
                            </div>
                        </>
                    )}
                </Right>
            </Layout>
        </>
    );
};

export default Slots;
