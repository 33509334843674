import { ColorChangeHandler, ColorResult, SketchPicker } from "react-color";
import React, { useState } from "react";

import reactCSS from "reactcss";
import rgbHex from "rgb-hex";

interface ColorPickerProps {
    color: string;
    onChangeComplete: (string: any) => void;
}

const ColorPicker: React.FC<ColorPickerProps> = ({ color, onChangeComplete }) => {
    const [displayPicker, setDisplayPicker] = useState<boolean>(false);

    const handleClick = () => setDisplayPicker((ps) => !ps);

    const handleClose = () => setDisplayPicker(false);

    const handleChange: ColorChangeHandler = (color: ColorResult): void => {
        onChangeComplete("#" + rgbHex(color.rgb.r, color.rgb.g, color.rgb.b, color.rgb.a));
    };

    const styles = reactCSS({
        default: {
            color: {
                width: "200px",
                height: "14px",
                borderRadius: "5px",
                background: color
            },
            swatch: {
                padding: "5px",
                background: "#fff",
                borderRadius: "1px",
                boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
                display: "inline-block",
                cursor: "pointer"
            },
            popover: {
                position: "absolute",
                zIndex: "2"
            },
            cover: {
                position: "fixed",
                top: "0px",
                right: "0px",
                bottom: "0px",
                left: "0px"
            }
        }
    }) as any;

    return (
        <div>
            <div style={styles.swatch} onClick={handleClick}>
                <div style={styles.color} />
            </div>
            {displayPicker ? (
                <div style={styles.popover}>
                    <div style={styles.cover} onClick={handleClose} />
                    <SketchPicker color={color} onChange={handleChange} />
                </div>
            ) : null}
        </div>
    );
};

export default ColorPicker;
