import { Error422 } from "@store/index";
import { message } from "antd";

interface PerformMutationArgs {
    mutation: () => Promise<any>;
    successMessage: string;
    errorMessage: string;
    formFieldsSetter?: (error: Error422) => void;
    formFieldsResetter?: Function;
    onSuccessCallback?: Function;
    onErrorCallback?: Function;
}

export const performMutation = async (mutationArgs: PerformMutationArgs) => {
    try {
        await mutationArgs.mutation();
        message.success(mutationArgs.successMessage);
        mutationArgs.onSuccessCallback && mutationArgs.onSuccessCallback();
    } catch (error: any) {
        if (error.status === 403) {
            message.error(mutationArgs.errorMessage + ": " + error.data.message);
        } else {
            message.error(mutationArgs.errorMessage);
        }
        if (error.status === 422) {
            mutationArgs.formFieldsSetter && mutationArgs.formFieldsSetter(error.data);
        } else {
            mutationArgs.formFieldsResetter && mutationArgs.formFieldsResetter();
        }
        mutationArgs.onErrorCallback && mutationArgs.onErrorCallback();
    }
};
