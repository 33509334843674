import { MultilingualFormInput } from "@components/forms/multilingual";
import inputs from "./intputs";

export const optionInputs: MultilingualFormInput[] = [
    ...inputs,
    {
        name: "description",
        label: "Description",
        type: "rich-text-editor"
    }
];
