import React from "react";
import { Editor } from "@tinymce/tinymce-react";

export interface RichTextEditorProps {
    placeholder?: string;
    value?: any;
    onChange?: (value: any) => void;
}

const RichTextEditor: React.FC<RichTextEditorProps> = ({ placeholder, value, onChange }) => {
    return (
        <Editor
            onEditorChange={onChange}
            apiKey={process.env.REACT_APP_TINY_LICENCE}
            value={value}
            init={{
                height: 300,
                menubar: false,
                forced_root_block: false,
                deprecation_warnings: false,
                placeholder,
                plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount autoresize"
                ],
                toolbar:
                    "undo redo | formatselect | " +
                    "bold italic backcolor | alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist outdent indent | " +
                    "removeformat | help"
            }}
        />
    );
};

export default RichTextEditor;
