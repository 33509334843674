import { Button, Dropdown, List, Menu, Space } from "antd";
import React, { useState } from "react";

import { GalleryItem } from "@store/api/types";
import { sizeFormatter } from "human-readable";
import { useParams } from "react-router-dom";

interface GalleryListItemProps {
    item: GalleryItem;
    keyNameToUpload: string | null;
    setIsDrawerOpen: (val: boolean) => void;
    attachMedia: (mediaInfo: {
        surveyId: string;
        media_id: string;
        fieldname: string | null;
    }) => Promise<any>;
}

const GalleryListItem: React.FC<GalleryListItemProps> = ({
    item,
    keyNameToUpload,
    setIsDrawerOpen,
    attachMedia
}) => {
    const { id: surveyId } = useParams();
    const defaults = sizeFormatter();
    const [background, setBackground] = useState<"black" | "white">("white");

    return (
        <List.Item
            key={item.filename}
            extra={
                <img
                    width={272}
                    style={{ background: background }}
                    src={item.url}
                    alt="gallery item"
                />
            }
        >
            <List.Item.Meta
                title={item.filename}
                description={"Size: " + defaults(parseInt(item.size))}
            />
            <Space direction="vertical">
                <Dropdown
                    overlay={
                        <Menu>
                            <Menu.Item key="0" onClick={() => setBackground("black")}>
                                Black
                            </Menu.Item>
                            <Menu.Item key="1" onClick={() => setBackground("white")}>
                                White
                            </Menu.Item>
                        </Menu>
                    }
                >
                    <span style={{ cursor: "pointer", textDecoration: "underline" }}>
                        Change image container background color (only for preview purposes)
                    </span>
                </Dropdown>
                <Button
                    onClick={async () => {
                        await attachMedia({
                            surveyId: surveyId!,
                            media_id: item.id,
                            fieldname: keyNameToUpload
                        });
                        setIsDrawerOpen(false);
                    }}
                    type="primary"
                >
                    Attach
                </Button>
            </Space>
        </List.Item>
    );
};

export default GalleryListItem;
