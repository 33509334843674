import { Button, List, PageHeader, Popconfirm, Tooltip } from "antd";
import {
    DeleteOutlined,
    DownCircleOutlined,
    EditOutlined,
    PlusOutlined,
    SortAscendingOutlined,
    UpCircleOutlined
} from "@ant-design/icons";
import { Link, Outlet } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";
import {
    useDeleteSurveyQuestionOptionMutation,
    useGetQuestionOptionsQuery,
    useUpdateOptionsOrderMutation
} from "@store/api/surveys";
import { useNavigate, useParams } from "react-router";

import { Page } from "@components/layout";
import { performMutation } from "@utils/perform-mutation";
import styles from "./QuestionOptions.module.scss";

const QuestionOptions: React.FC = () => {
    const navigate = useNavigate();
    const { id: surveyId, questionId } = useParams();
    const [order, toggleOrder] = useState<boolean>(false);
    const [items, setItems] = useState<any>([]);
    const [needToSaveOrder, toggleNeedToSaveOrder] = useState<boolean>(false);
    const { data, isFetching } = useGetQuestionOptionsQuery(questionId);
    const [deleteOption] = useDeleteSurveyQuestionOptionMutation();
    const [updateOrder, { isLoading }] = useUpdateOptionsOrderMutation();

    const getDefaultItemsState = useCallback(() => {
        setItems(data);
    }, [data]);

    const handleDeleteOption = async (id: string) => {
        await performMutation({
            mutation: async () => await deleteOption(id).unwrap(),
            successMessage: "The option was deleted successfully",
            errorMessage: "There was an error while deleting the option"
        });
    };

    const handleToggleOrder = () => {
        if (order && needToSaveOrder) {
            toggleNeedToSaveOrder(false);
            getDefaultItemsState();
        }

        toggleOrder(!order);
    };
    const handlePutUp = (i: number) => {
        const _items = [...items];
        const temp = _items[i];
        _items[i] = _items[i - 1];
        _items[i - 1] = temp;
        setItems(_items);
        toggleNeedToSaveOrder(true);
    };
    const handlePutDown = (i: number) => {
        const _items = [...items];
        const temp = _items[i];
        _items[i] = _items[i + 1];
        _items[i + 1] = temp;
        setItems(_items);
        toggleNeedToSaveOrder(true);
    };

    const handleSaveOrder = async () => {
        const order = items.map(({ id }: any) => id);
        await performMutation({
            mutation: async () => await updateOrder({ questionId, order }),
            successMessage: "The order of the options was changed successfully",
            errorMessage: "There was an error while updating the order of the questions"
        });

        toggleNeedToSaveOrder(false);
        toggleOrder(false);
    };

    useEffect(() => {
        if (data) {
            getDefaultItemsState();
        }
    }, [data, getDefaultItemsState]);

    return (
        <div>
            <PageHeader
                onBack={() => navigate(`/survey/${surveyId}/questions/${questionId}`)}
                title="Survey question options"
            />
            <Page>
                <List
                    loading={isFetching}
                    size="small"
                    bordered
                    header={
                        <div className={styles.listHeader}>
                            <div className={styles.title}>
                                <h3>Options</h3>
                            </div>
                            <div className={styles.actions}>
                                {!order && (
                                    <Link
                                        to={`/survey/${surveyId}/questions/${questionId}/options/new`}
                                    >
                                        <Button icon={<PlusOutlined />} size="small" type="primary">
                                            Add new
                                        </Button>
                                    </Link>
                                )}
                                {needToSaveOrder && (
                                    <Button
                                        loading={isLoading}
                                        size="small"
                                        onClick={handleSaveOrder}
                                    >
                                        Save order
                                    </Button>
                                )}
                                <Tooltip
                                    placement="top"
                                    title={`${order ? "Disable" : "Enable"} order`}
                                >
                                    <Button
                                        type={order ? "primary" : "default"}
                                        onClick={handleToggleOrder}
                                        size="small"
                                        icon={<SortAscendingOutlined />}
                                    />
                                </Tooltip>
                            </div>
                        </div>
                    }
                    dataSource={items || []}
                    renderItem={({ id, title }: any, i: number) => (
                        <List.Item
                            actions={
                                order
                                    ? [
                                          <div key="up">
                                              {i !== items.length - 1 && (
                                                  <DownCircleOutlined
                                                      onClick={() => handlePutDown(i)}
                                                  />
                                              )}
                                          </div>,
                                          <div key="down">
                                              {i !== 0 && (
                                                  <UpCircleOutlined
                                                      onClick={() => handlePutUp(i)}
                                                  />
                                              )}
                                          </div>
                                      ]
                                    : [
                                          <Link
                                              to={`/survey/${surveyId}/questions/${questionId}/options/${id}`}
                                              key="link"
                                          >
                                              <EditOutlined />
                                          </Link>,
                                          <Popconfirm
                                              placement="left"
                                              key="delete"
                                              title="Are you sure to delete this option?"
                                              okText="Delete"
                                              onConfirm={() => handleDeleteOption(id)}
                                          >
                                              <DeleteOutlined className={styles.delete} />
                                          </Popconfirm>
                                      ]
                            }
                        >
                            {title.en}
                        </List.Item>
                    )}
                />
                <Outlet />
            </Page>
        </div>
    );
};

export default QuestionOptions;
